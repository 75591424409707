@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;700&display=swap');

* {
    font-family: 'Nunito', sans-serif;
}

.my-fullscreen {
    overflow-y: scroll !important;
}

.sidebar {
    a {
        text-decoration: none !important;
    }
}

.rbc-calendar {
    min-height: 600px;
}

/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

#section-to-print .paperRef {
    max-width: 70% !important;
    margin: 0 auto !important;
}

@media print {
    body * {
        visibility: hidden;
    }

    #section-to-print,
    #section-to-print * {
        visibility: visible;
    }

    #section-to-print {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, 0%);
        width: 90%;
    }

    #section-to-print .paperRef {
        max-width: 100% !important;
    }

    #section-to-print table tbody tr td {
        font-size: 18px !important;
    }

    #section-to-print table thead tr th {
        font-size: 19px !important;
    }

    #section-to-print .address {
        font-size: 18px !important;
    }

    #section-to-print .caption {
        font-size: 14px !important;
    }

    #section-to-print .hide-on-print {
        visibility: hidden;
    }
}

#payrolls-to-print {
    display: none;
}

@media print {
    body * {
        visibility: hidden;
    }

    #payrolls-to-print,
    #payrolls-to-print * {
        visibility: visible;
    }

    #payrolls-to-print {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }

    #payrolls-to-print .single-payroll {
        page-break-inside: avoid;
    }

    #payrolls-to-print table tbody tr td {
        font-size: 14px !important;
    }

    #payrolls-to-print table thead tr th {
        font-size: 14px !important;
    }

    #payrolls-to-print .hide-on-print {
        visibility: hidden;
    }

    .hidden-payrolls {
        display: none;
    }
}

#payrolls-to-print-bangla {
    display: none;
}

@media print {
    body * {
        visibility: hidden;
    }

    #payrolls-to-print-bangla,
    #payrolls-to-print-bangla * {
        visibility: visible;
    }

    #payrolls-to-print-bangla {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }

    #payrolls-to-print-bangla .single-payroll {
        page-break-inside: avoid;
    }

    #payrolls-to-print-bangla table tbody tr td {
        font-size: 8px !important;
    }

    #payrolls-to-print-bangla table thead tr th {
        font-size: 8px !important;
    }

    #payrolls-to-print-bangla .hide-on-print {
        visibility: hidden;
    }

    .hidden-payrolls {
        display: none;
    }
}

@media print {
    @page {
        size: A4 landscape;
    }

    body * {
        visibility: hidden;
    }

    #settlement-section-to-print,
    #settlement-section-to-print * {
        visibility: visible;
    }

    #settlement-section-to-print {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }

    #settlement-section-to-print table tbody tr td {
        font-size: 8px !important;
    }

    #settlement-section-to-print table thead tr th {
        font-size: 8px !important;
    }

    #settlement-section-to-print .hide-on-print {
        visibility: hidden;
    }
}

@media print {
    @page {
        size: legal landscape;
    }

    body * {
        visibility: hidden;
    }

    #section-to-print2,
    #section-to-print2 * {
        visibility: visible;
    }

    #section-to-print2 {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }

    #section-to-print2 .hide-on-print {
        visibility: hidden;
    }
}

@media print {
    body * {
        visibility: hidden;
    }

    #section-to-print3,
    #section-to-print3 * {
        visibility: visible;
    }

    #section-to-print3 {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }

    #section-to-print3 table tbody tr td {
        font-size: 8px !important;
    }

    #section-to-print3 table thead tr th {
        font-size: 8px !important;
    }

    #section-to-print3 .hide-on-print {
        visibility: hidden;
    }
}

.tableContianer {
    max-width: 100%;
    overflow-x: auto;
}

.tableContianer table tr {
    outline: 1px solid #a6a6a6;
}

.tableContianer table tr th {
    outline: 1px solid #a6a6a6;
    background: #c4c4c4;
    min-width: 60px;
    padding: 6px;
}

.tableContianer table tr td {
    outline: 1px solid #a6a6a6;
    padding: 6px;
}

@media print {
    body * {
        visibility: hidden;
    }

    #challan-to-print,
    #challan-to-print * {
        visibility: visible;
    }

    #challan-to-print {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }

    #challan-to-print table tbody tr td {
        font-size: 12px !important;
    }

    #challan-to-print table thead tr th {
        font-size: 12px !important;
    }

    #challan-to-print .hide-on-print {
        visibility: hidden;
    }
}

@media print {
    body * {
        visibility: hidden;
    }

    #challan-tdx-to-print {
        width: 100%;
    }

    #challan-tdx-to-print,
    #challan-tdx-to-print * {
        visibility: visible;
        print-color-adjust: exact;
    }

    #challan-tdx-to-print {
        position: absolute;
        left: 0;
        width: 100%;
    }

    #challan-tdx-to-print table tbody tr td {
        font-size: 12px !important;
    }

    #challan-tdx-to-print table thead tr th {
        font-size: 12px !important;
    }

    #challan-tdx-to-print .hide-on-print {
        visibility: hidden;
    }
}

@media print {
    body * {
        visibility: hidden;
    }

    #challan-to-print-2,
    #challan-to-print-2 * {
        visibility: visible;
    }

    #challan-to-print-2 {
        position: absolute;
        width: 100%;
        left: 0;
        background: transparent;
    }

    #challan-to-print-2 table tbody tr td {
        font-size: 12px !important;
    }

    #challan-to-print-2 table thead tr th {
        font-size: 12px !important;
    }

    #challan-to-print-2 .hide-on-print {
        visibility: hidden;
    }
}

@media print {
    body * {
        visibility: hidden;
    }

    #challan-tdx-to-print-2,
    #challan-tdx-to-print-2 * {
        visibility: visible;
        print-color-adjust: exact;
    }

    #challan-tdx-to-print-2 {
        width: 100%;
        position: absolute;
        left: 0;
        top: 22in;
    }

    #challan-tdx-to-print-2 table tbody tr td {
        font-size: 12px !important;
    }

    #challan-tdx-to-print-2 table thead tr th {
        font-size: 12px !important;
    }

    #challan-tdx-to-print-2 .hide-on-print {
        visibility: hidden;
    }
}

.basic-single {
    position: absolute;
}

//Custom Radio Checkbox
.custom_radio_checkbox_area {
    position: relative;

    input {
        cursor: pointer;
        transform: scale(1.4);
        -webkit-transform: scale(1.4);
        -moz-transform: scale(1.4);
        -ms-transform: scale(1.4);
        -o-transform: scale(1.4);

        &:hover {
            opacity: 0.8;
        }
    }
}

.custom_leave_select_area {
    display: flex;
    align-items: center;
    gap: 5px;

    .leave_select_area {
        display: none;
    }

    input:checked ~ .leave_select_area {
        display: flex;
    }
}

// .total_time_row {
//   input {
//     width: 45px;
//     padding: 5px 11px;
//     height: 27px;
//   }
// }

//Fix otp Responsive
.otp_pin_area > div {
    flex-wrap: wrap;
    gap: 5px 0;
}

@media print {
    @page {
        size: a4 portrait;
        margin: 2mm;
    }

    body {
        zoom: 69%;
    }

    body * {
        visibility: hidden;
    }

    #printable-employee-profile,
    #printable-employee-profile * {
        visibility: visible;
    }

    #printable-employee-profile table {
        page-break-inside: avoid !important;
    }

    #printable-employee-profile {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        zoom: 0.8;
    }

    #printable-employee-profile .hide-on-print {
        visibility: hidden;
    }
}

.linkToButton {
    text-decoration: none;
}

@media print {
    @page {
        size: a4 portrait;
    }

    body {
        zoom: 69%;
    }

    body * {
        visibility: hidden;
    }

    #po__details__print,
    #po__details__print * {
        visibility: visible;
        print-color-adjust: exact;
        zoom: 97%;
    }

    #po__details__print {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }

    #po__details__print .hide-on-print {
        visibility: hidden;
    }
}

.single_date_wrapper {
    .react-datepicker__navigation--next,
    .react-datepicker__navigation--previous,
    .react-datepicker-year-header,
    .react-datepicker__current-month {
        display: none;
    }
}

.react-datepicker-wrapper {
    width: 100%;

    .react-datepicker__close-icon {
        right: 28px;

        &::after {
            background-color: #02b19a;
        }
    }
}

.react-datepicker-popper {
    z-index: 10;
}

//React select
.select__control,
.select-user__control {
    min-height: 40px !important;

    @media (max-width: 599px) {
        min-height: 38px !important;
    }
}

.select__menu-portal {
    z-index: 100000 !important;

    @media (max-width: 1299px) {
        .select__menu-list {
            max-height: 140px;
        }
    }
}
